<template>
  <div
      class="board-layout-col"
  >
    <div class="board-header">
      <div class="board-header-title">
        <div class="title-count">
          <h3>{{ ce_status.name }}</h3>
          <span class="count-box">{{ count || 0 }}</span>
        </div>
        <div
            class="card-menu-button"
            :ref="`popupBtn${index}`"
            @click="showPopupMenu(ce_status.ce_status_id)"
            tabindex="0"
            @focusout="closePopupMenu"
        >
          <i class="show-more bx bx-dots-horizontal-rounded"></i>
          <ul
              class="card-menu-popup"
              :class="{'card-menu-popup--left': popupLeft, 'card-menu-popup--bottom': popupBottom}"
              v-if="showMenuItem === ce_status.ce_status_id"
              v-bind:style="getPopupPosition(`popupBtn${index}`)"
          >
            <li class="parent" @click="hideStatusForMe(ce_status.ce_status_id)">
              <div class="title">
                Hide Status for Me
              </div>
            </li>
            <li v-if="!isDisplayBoardUser" class="parent" @click="hideStatusForAll(ce_status.ce_status_id)">
              <div class="title">
                Hide Status for All
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="board-search">
        <div class="board-search-input-container">
          <input
              v-model="filter"
              type="text"
              class="search-input"
              placeholder="Search here"
              @change="debouncedOnChangeFilter"
              @input="debouncedOnChangeFilter"/>
        </div>
        <button class="filter">
          <i class="bx bx-filter-alt"></i>
        </button>
      </div>
    </div>
    <div class="board-layout" ref="boardAllItems">
      <draggable
          :data-status-name="ce_status.original_name"
          :data-card-status="'all'+ce_status.ce_status_id"
          :data-card-status-id="ce_status.ce_status_id"
          element="div"
          :animation="200"
          :group="{ name: 'people', pull: 'clone', put: true }"
          v-model="localCards"
          @start="onDragStart"
          :disabled="!isStatusActive || isShowMenuItem "
          @end="onDragEnd"
          :list="null"
          class="board-layout-draggable"
      >
        <template class="" v-for="element in localCards">
          <div
              :data-itemArray="element.card_id"
              class="board-item-holder"
          >
            <div
                class="board-item noselect"
                @contextmenu.prevent="openMenuForBoardCards($event, element.title, element.card_id, element.card_number)"
            >
              <div class="title-list-container">
                <h4 class="board-item-title">
                  <a
                      @click="onCardsRowClick(element)"
                      class="filter_search_field clickable board-item-title-container"
                  >
                    {{ element.card_number }}
                    <template v-if="element.priority">

                      <span v-if="element.priority === 'OnHold'" class="board-item-title-status on-hold">On Hold</span>

                      <span v-else-if="element.priority !== 'Undo'" class="board-item-title-status"
                            :class="element.priority.toLowerCase()">{{ element.priority }}</span>
                    </template>
                  </a>
                  <div :ref="`cardAllMenuItemButton${element.card_id}`"
                       class="card-menu-button"
                       tabindex="0"
                       @focusout="closePopupMenu"
                  >
                    <i @click="showPopupMenu(element.card_id, true)"
                       class="show-more bx bx-dots-horizontal-rounded"></i>
                    <ul
                        class="card-menu-popup"
                        :class="{'card-menu-popup--left': popupLeft, 'card-menu-popup--bottom': popupBottom}"
                        v-if="Number(showMenuItem) === Number(element.card_id)"
                        v-bind:style="getPopupPosition(`cardAllMenuItemButton${element.card_id}`)"
                    >
                      <li class="parent">
                        <div class="title">
                          <i v-if="popupLeft"
                             class="bx bx-chevron-left"></i>
                          Set Priority
                          <i v-if="!popupLeft"
                             class="bx bx-chevron-right"></i>
                        </div>
                        <div class="sub-menu">
                          <ul class="sub-menu-inner">
                            <li @click="setCardPriority(element.card_id, 'High')">
                              <span class="status-color high-color"></span>High Priority
                            </li>
                            <li @click="setCardPriority(element.card_id, 'Medium')">
                              <span class="status-color medium-color"></span>Medium Priority
                            </li>
                            <li @click="setCardPriority(element.card_id, 'Low')">
                              <span class="status-color low-color"></span>Low Priority
                            </li>
                            <li @click="setCardPriority(element.card_id, 'OnHold')">
                              <span class="status-color on-hold-color"></span> On Hold
                            </li>
                            <li v-if="isHasPriority(element.priority)"
                                @click="setCardPriority(element.card_id, 'Undo')">
                              <span class="status-color undo-priority-color"></span> Undo Priority
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="parent">
                        <div class="title">
                          <i v-if="popupLeft"
                             class="bx bx-chevron-left"></i>
                          Assign
                          <i v-if="!popupLeft"
                             class="bx bx-chevron-right"></i>
                          <div class="sub-menu">
                            <ul class="sub-menu-inner">
                              <!--                                                                                    <input class="input-assign-to" type="text" placeholder="Search" v-model="filterAssignTo">-->
                              <li @click="assignedToPersonOnBoard({item: element}, user.id)"
                                  :key="'assignee-' + user.id"
                                  v-for="user in computedActiveUsers">
                                <i class='bx bx-user mr-1'></i>
                                {{ user.name }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                      <li class="parent">
                        <div class="title">
                          <i v-if="popupLeft"
                             class="bx bx-chevron-left"></i>
                          Card Mode
                          <i v-if="!popupLeft"
                             class="bx bx-chevron-right"></i>
                        </div>
                        <div class="sub-menu">
                          <ul class="sub-menu-inner">
                            <li v-if="element.state && element.state != 0"
                                @click="closePopupMenu(); $emit('onRightClickByMenu', 0, {item:{card_id:element.card_id}})"
                            >Mark As Open
                            </li>
                            <li v-if="element.state && element.state != 1"
                                @click="closePopupMenu(); $emit('onRightClickByMenu', 1, {item:{card_id:element.card_id}})"
                            >Mark As Active
                            </li>
                            <li v-if="element.state && element.state != 3"
                                @click="closePopupMenu(); $emit('onRightClickByMenu', 3, {item:{card_id:element.card_id}})"
                            >Mark As Closed
                            </li>
                            <li v-if="element.activity
                           && ((element.activity.Assignment && element.activity.Assignment.from)
                           || (element.activity.External && element.activity.External.date)
                           || (element.activity.Email && element.activity.Email.date)
                           || (element.activity.Message && element.activity.Message.from))"
                                @click="closePopupMenu(); $emit('markAsRead', element.card_id)"
                            >
                              Mark As Read
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="parent">
                        <div class="title">
                          <i v-if="popupLeft"
                             class="bx bx-chevron-left"></i>
                          Open
                          <i v-if="!popupLeft"
                             class="bx bx-chevron-right"></i>
                        </div>
                        <div class="sub-menu">
                          <ul class="sub-menu-inner">
                            <li @click="openCardInNewTab(element.card_id)">
                              in New Tab
                            </li>
                            <li @click="openCardInNewWindow(element.card_id)">
                              in New Window
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="parent" v-if="!isCustomerUser">
                        <div class="title">
                          <i v-if="popupLeft"
                             class="bx bx-chevron-left"></i>
                          Add to
                          <i v-if="!popupLeft"
                             class="bx bx-chevron-right"></i>
                        </div>
                        <div class="sub-menu">
                          <ul class="sub-menu-inner">
                            <li @click="closePopupMenu(); $emit('addToVehicleInBoard', element.card_id, element.card_number)">
                              Vehicle In Board
                            </li>
                            <li @click="closePopupMenu(); $emit('addToDepartureBoard', element.card_id, element.card_number)">
                              Departure Board
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li v-if="false" class="parent" @click="closePopupMenu(); $emit('acceptORMJob', element.card_id)">
                        <div class="title">
                          Accept ORM JOB
                        </div>
                      </li>

                      <li v-if="false" class="parent" @click="closePopupMenu(); $emit('openDeclineORMJobModal', element.card_id)">
                        <div class="title">
                          Decline ORM Job
                        </div>
                      </li>
                      <li class="parent"
                          @click="closePopupMenu(); $emit('deleteCard',element.card_id)">
                        <div class="title">
                          Delete
                        </div>
                      </li>
                    </ul>
                  </div>
                </h4>
                <ul class="board-view-list">
                  <li class="board-view-list-fw">{{ element.customer_name }}</li>
                  <li class="board-view-list-fw">{{ element.rego_number }}</li>
                  <li>{{ `${element.make} ${element.model}` }}</li>
                  <li class="colour-insurance"
                      :style="getInsurerNameStyle(element.insurerCardColorId)">
                    {{ element.insurance || 'Non-Insurance' }}
                  </li>
                </ul>
              </div>
              <card-actions :item="element" :insurerColor="getInsurerColor(element.prodTimeColorId)" :name="`All-${element.card_id}`"/>
            </div>
          </div>
        </template>
      </draggable>
      <div v-if="isShowMore" @click="$emit('increaseLimit')" class="show-more-btn">
        <div class="">Show More</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Axios from 'axios'
import draggable from 'vuedraggable'
import _ from 'lodash'
import CardActions from '../card-actions'

export default {
  name: 'cards-board-column',
  props: {
    count: {
      type: Number,
      default: 0
    },
    cards: {
      type: Array,
      default () {
        return []
      }
    },
    isShowMore: {
      type: Boolean,
      default () {
        return false
      }
    },
    index: {
      type: Number,
      default: 0
    },
    ce_status: {
      type: Object,
      default () {
        return {}
      }
    },
    categories: {
      type: Array,
      default() {
        return []
      }
    },
  },
  components: {
    draggable,
    CardActions
  },
  mounted () {
    this.localCards = this.cards
    document.addEventListener('scroll', this.closePopupMenu)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  unmounted () {
    window.removeEventListener('scroll', this.closePopupMenu)
  },
  watch: {
    cards: {
      handler (v) {
        let vCopy = v ? v.slice() : []
        this.localCards.splice(0, this.localCards.length)
        this.$nextTick(() => {
          this.localCards.splice(0, this.localCards.length)
          let value = _.orderBy(_.uniqBy(vCopy, 'card_id'), ['boardSort', 'created_on'],['asc'])
          this.localCards.push(...value)
        })
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      isStatusActive: 'isStatusActive',
      getterVisibleUsers: 'visibleUsers',
      isCustomerUser: 'isCustomerUser',
      isDisplayBoardUser: 'isDisplayBoardUser',
      getterInsurerCardColor: 'getInsurerCardColor',
    }),
    computedShortCategories() {
      let obj = {}
      _.forEach(this.categories, (c) => {
        obj[c.id] = c.color
      })
      return obj
    },
    isShowMenuItem() {
      return !(this.showMenuItem === null)
    },
    computedActiveUsers () {
      let users = this.getterVisibleUsers.slice()
      users.unshift({
        id: null,
        name: 'None',
        groupId: null,
        group_id: null,
        isActive: true,
        isVisible: true,
        lastName: null,
        firstName: null,
        role: null,
      })
      return users
    },
  },
  data () {
    return {
      showMenuItem: null,
      popupLeft: false,
      popupBottom: false,
      filter: '',
      localCards: []
    }
  },
  created () {
    this.debouncedOnChangeFilter = _.debounce(this.onChangeFilter, 300)
  },
  methods: {
    getInsurerColor(id) {
      if (!id) {
        return ''
      }
      return this.computedShortCategories[id]
    },
    getInsurerNameStyle(id) {
      if (!id) {
        return null
      }
      let data = this.getterInsurerCardColor?.[id];
      if (data) {
        return {
          color: data.color,
          backgroundColor: data.background,
        }
      }
      return null
    },
    onDragEnd(v) {
      clearInterval(this.interval)
      this.$emit('isDraggedAll', {evt: v, ids: this.getIds(this.localCards)})
    },
    onDragStart () {
      this.interval = setInterval(() => {
        this.$store.commit('changeUpdateStatus', false)
      }, 10)
      let rows = document.getElementsByClassName('board-item');
      _.forEach(rows, (elem) => {
        elem.style.backgroundColor = "";
        elem.style.border = "2px solid #bebebe";
      })
    },
    getIds (v) {
      return _.map(v, (c) => {
        return c.card_id
      })
    },
    getIds2() {
      return _.map(this.localCards, (c) => {
        return c.card_id
      })
    },
    onChangeFilter () {
      this.$emit('changedFilter', this.filter)
    },
    openCardInNewTab (cardId) {
      window.open(
          window.location + '/view/' + cardId,
          '_blank'
      )
      this.closePopupMenu()
    },
    openCardInNewWindow (cardId) {
      window.open(
          window.location + '/view/' + cardId,
          '_blank',
          'toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,width=' +
          screen.width +
          ',height=' +
          screen.height
      )
      this.closePopupMenu()
    },
    assignedToPersonOnBoard (data, user) {
      this.$emit('assignedToPerson', data, user)
      this.closePopupMenu()
    },
    isHasPriority (priority) {
      if (priority === 'Low' || priority === 'Medium' || priority === 'High' || priority === 'OnHold') {
        return true
      }
      return false
    },
    setCardPriority (cardId, priority) {
      this.closePopupMenu()
      this.$emit('setCardPriority', cardId, priority)
    },
    openMenuForBoardCards (event, title, cardId, cardNumber) {
      this.$emit('openMenuForBoardCards', event, title, cardId, cardNumber)
    },
    onCardsRowClick: function (item, index, event) {
      if (this.isDisplayBoardUser) {
        return
      }
      this.$router.push('/cards/view/' + item.card_id)
    },
    hideStatusForAll (id) {
      NProgress.start()
      Axios({
        method: 'post',
        responseType: 'json',
        url: '/fe/cards/kanban/hide-status/all',
        validateStatus: function (status) {
          return status < 500
        },
        data: {
          statusId: id,
        },
      }).then(response => {
        if (response.status === 200 && response.data._status) {
          this.$store.commit('card/updateVendorHiddenStatuses', response.data.vendorHiddenStatuses)
        }
      })
          .finally(() => {
            NProgress.done()
          })
    },
    hideStatusForMe (id) {
      NProgress.start()
      Axios({
        method: 'post',
        responseType: 'json',
        url: '/fe/cards/kanban/hide-status/me',
        validateStatus: function (status) {
          return status < 500
        },
        data: {
          statusId: id,
        },
      }).then(response => {
        if (response.status === 200 && response.data._status) {
          this.$store.commit('card/updateUserHiddenStatuses', response.data.userHiddenStatuses)
        }
      })
          .finally(() => {
            NProgress.done()
          })
    },
    setPopupLeft () {
      this.popupLeft = true
    },
    setPopupBottom () {
      this.popupBottom = true
    },
    getPopupPosition: function (name) {
      if (!this.$refs[name] || !this.$refs[name][0]) return
      const el = this.$refs[name][0]
      const elRect = el.getBoundingClientRect()
      const { left, bottom, width } = elRect

      // 300 approx popup open with submenu
      const maxLeft = window.innerWidth - 300
      const maxBottom = window.innerHeight - 320

      if (bottom > maxBottom) {
        // for bottom
        // will only set card-popup-menu
        // to set conditional state by class
        this.setPopupBottom()
      }

      if (left < maxLeft) {
        return {
          position: 'fixed',
          top: `${bottom + 5}px`,
          left: `${left}px`,
        }
      } else {
        this.setPopupLeft()
        return {
          position: 'fixed',
          top: `${bottom + 5}px`,
          left: `${left - (140 - width)}px`,
        }
      }
    },
    showPopupMenu (cardId, isFromTile = false) {
      if (this.isDisplayBoardUser && isFromTile) {
        return
      }
      if (!this.isStatusActive) {
        return
      }
      if (this.showMenuItem === cardId) {
        this.showMenuItem = null
        return
      }
      this.showMenuItem = cardId
    },
    closePopupMenu () {
      // console.log('closePopupMenu' )
      this.showMenuItem = null
      this.popupLeft = false
      this.popupBottom = false
    },
  }
}
</script>

<style scoped>
.colour-insurance{
  font-weight: 700;
  display: inline !important;
  border-radius: 5px;
  padding: 1px 3px
}
.show-more-btn {
  display: flex;
  justify-content: end;
  flex-direction: column;
  align-items: center;
}

.show-more-btn div {
  background-color: #5E79FF !important;
  color: #FFFFFF;
  width: 100px;
  height: 31px;
  border: none;
  border-radius: 3px;
  font-size: 13px;
  font-weight: bold;
  background: none;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}


* {
  box-sizing: border-box;
}

.board-layout {
  border-right: 2px solid #E9ECEE;
  height: calc(100% - 100px);
  padding: 0 0 10px 10px;
  overflow-x: hidden;
  border-radius: 3px;
}

/* Scrollbar Width */

.board-layout {
  scrollbar-width: thin;
  scrollbar-color: #B7B8C0; /* #5e79ff; */
}

.board-layout::-webkit-scrollbar-thumb {
  background-color: #B7B8C0; /* #5e79ff; */
}

.board-layout::-webkit-scrollbar {
  width: 7px;
}

/* End Scrollbar Width */

.board-layout-col {
  flex-shrink: 0;
  max-width: 165px;
  width: 100%;
  border-radius: 3px;
  background: rgba(22, 25, 50, 0.04);
}

.board-layout-col + .board-layout-col {
  margin-left: 10px;
}

.board-header {
  height: 84px;
  padding: 14px 10px 10px 10px;
  padding-right: 15px;
}

.board-search {
  display: flex;
  margin-top: 7px;
}

.board-search-input-container {
  position: relative;
  margin-right: 5px;
}

.board-search input::placeholder {
  color: #1C1F39;
  font-size: 12px;
  position: relative;
  height: 30px;
}

.board-search input {
  font-size: 13px;
  border: none;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  min-width: 1px;
  position: relative;
  width: 100%;
  border-radius: 3px;
}

.board-search-input-container:after {
  content: "\ebf7";
  font-family: "boxicons" !important;
  display: block;
  width: 16px;
  height: 16px;
  position: absolute;
  right: 7px;
  top: -1px;
  font-size: 22px;
  color: rgba(28, 31, 57, 0.5);
}

.board-search button {
  border-radius: 3px;
  background: #FFFFFF;
  color: rgba(28, 31, 57, 0.5);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  font-weight: bold;
  margin: 0;
  border: 0;
}

.board-search button i {
  font-size: 22px;
}

.board-header h3 {
  color: #1C1F39;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  margin-bottom: 0;
}

.board-item {
  cursor: move;
  background: #FFFFFF;
  border-radius: 3px;
  padding: 10px 8px 6px 10px;
  margin-bottom: 10px;
  width: 140px;
  min-height: 130px;
  box-shadow: 0 8px 17px 0 rgba(50, 50, 50, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.board-item-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-menu-button {
  cursor: pointer;
  border: none;
  width: 23px;
  height: 21px;
  border-radius: 3px;
  font-size: 29px;
  padding: 0;
  margin: 0;
  color: rgba(28, 31, 57, 0.5);
  background: none;
}

.card-menu-button:hover {
  background: rgba(22, 25, 50, 0.04);
}

.card-menu-button > i {
  position: relative;
  top: -4px;
  left: -3px;
  display: block;
}

.board-item-title-container {
  display: flex;
  align-items: center;
}

.board-item-title-status {
  font-size: 9px;
  font-weight: bold;
  line-height: 12px;
  display: flex;
  justify-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-left: 5px;
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.board-item-title-status.high {
  background: rgba(255, 94, 94, 0.15);
  color: #FF5E5E;
}

.board-item-title-status.low {
  background: rgba(24, 208, 107, 0.15);
  color: #18D06B;
}

.board-item-title-status.medium {
  background: rgba(230, 213, 40, 0.15);
  color: #FFDB00;
}

.board-item-title-status.on-hold {
  color: #419abe;
  background: #ade7ff;
  margin-right: 17px;
}

.board-item-holder.sortable-chosen {
  opacity: 0.5;
  /*transition: all .5s;*/
}

.board-item-holder.sortable-ghost {
  opacity: 0.1;
  /*transition: all .5s;*/
}
.board-item-holder.sortable-chosen .board-item{
  background-color: #d3d8f3;
}
.board-item h4 {
  color: #142742;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 3px;
}

.board-item-title .board-item-title-container {
  color: #0a0a0a;
  font-size: 12px;
  font-weight: 900;
}

.board-item h4 a:hover {
  color: black;
}

.board-view-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.board-view-list .board-view-list-fw{
  font-weight: 900;
  color: #0a0a0a;
}

.board-view-list li {
  display: block;
  font-size: 12px;
  color: #142742;
  line-height: 15px;
}

.board-view-list li span {
  display: inline-block;
  color: #818A91;
  width: 38px;
}


.board-layout > div {
  align-content: flex-start;
}

.double-cols .board-layout > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -5px;
}

.double-cols .board-layout .board-item-holder {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 5px;
}

@media (max-width: 1200px) {
  .board-view .box-block {
    padding-right: 0;
  }
}

.board-layout-draggable {
  min-height: 100%;
}

</style>
