<template>
  <div
      class="box box-block bg-white cards"
      id="board-view"
  >
    <tabs
        ref="cards-board"
        cache-lifetime="0"
        @changed="boardTabChanged"
        :options="{useUrlFragment: false, defaultTabHash: computedDefaultCardBoardTabHash }"
    >
      <tab name="All" id="cards-all-board">
        <cards-board-columns
            ref="allBoard"
            :categories="categories"
            :cards="computedCardsAll"
            @isDraggedAll="v => isDraggedAll(v, 'allBoard')"
            @openMenuForBoardCards="openMenuForBoardCards"
            @setCardPriority="setCardPriority"
            @deleteCard="deleteCard"
            @assignedToPerson="assignedToPerson"
            @openDeclineORMJobModal="openDeclineModal"
            @addToVehicleInBoard="addToVehicleInBoard"
            @addToDepartureBoard="addToDepartureBoard"
            @onRightClickByMenu="onRightClickByMenu"
            @acceptORMJob="acceptORMJob"
            @markAsRead="markAsRead"
        ></cards-board-columns>
      </tab>
      <tab v-if="!isDisplayBoardUser" name="Open" id="cards-open-board">
        <cards-board-columns
            ref="openBoard"
            :categories="categories"
            :cards="computedOpenCards"
            @isDraggedAll="v => isDraggedAll(v, 'openBoard')"
            @openMenuForBoardCards="openMenuForBoardCards"
            @setCardPriority="setCardPriority"
            @deleteCard="deleteCard"
            @assignedToPerson="assignedToPerson"
            @openDeclineORMJobModal="openDeclineModal"
            @addToVehicleInBoard="addToVehicleInBoard"
            @addToDepartureBoard="addToDepartureBoard"
            @onRightClickByMenu="onRightClickByMenu"
            @acceptORMJob="acceptORMJob"
            @markAsRead="markAsRead"
        ></cards-board-columns>
      </tab>
      <tab v-if="!isDisplayBoardUser" name="Active" id="cards-active-board">
        <cards-board-columns
            ref="activeBoard"
            :categories="categories"
            :cards="computedActiveCards"
            @isDraggedAll="v => isDraggedAll(v, 'activeBoard')"
            @openMenuForBoardCards="openMenuForBoardCards"
            @setCardPriority="setCardPriority"
            @deleteCard="deleteCard"
            @assignedToPerson="assignedToPerson"
            @openDeclineORMJobModal="openDeclineModal"
            @addToVehicleInBoard="addToVehicleInBoard"
            @addToDepartureBoard="addToDepartureBoard"
            @onRightClickByMenu="onRightClickByMenu"
            @acceptORMJob="acceptORMJob"
            @markAsRead="markAsRead"
        ></cards-board-columns>
      </tab>
      <tab v-if="!isDisplayBoardUser" name="Closed" id="cards-closed-board">
        <cards-board-columns
            ref="closedBoard"
            :categories="categories"
            :cards="computedClosedCards"
            @isDraggedAll="v => isDraggedAll(v, 'closedBoard')"
            @openMenuForBoardCards="openMenuForBoardCards"
            @setCardPriority="setCardPriority"
            @deleteCard="deleteCard"
            @assignedToPerson="assignedToPerson"
            @openDeclineORMJobModal="openDeclineModal"
            @addToVehicleInBoard="addToVehicleInBoard"
            @addToDepartureBoard="addToDepartureBoard"
            @onRightClickByMenu="onRightClickByMenu"
            @acceptORMJob="acceptORMJob"
            @markAsRead="markAsRead"
        ></cards-board-columns>
      </tab>
      <template
          v-if="!isDisplayBoardUser && (isShopManagerUser || isProductionManagerUser || isEstimatorUser || isPDRTechnicianUser || isCustomerUser)"
          slot="nav-item-center">
        <div class="btn-group cards-view-button" role="group">
          <button v-if="!isDisplayBoardUser" @click="setViewTable" type="button" class="btn">Table</button>
          <button v-if="!isDisplayBoardUser" type="button" class="btn btn-primary active">Board
          </button>
        </div>
      </template>
      <template slot="nav-item-right">
        <div class="tabs-nav-right-search tabs-nav-right-search-font-weight">
          <!--                        <b-form-input-->
          <!--                                v-model="filter"-->
          <!--                                type="text"-->
          <!--                                class="form-control search-input"-->
          <!--                                placeholder="Search or Filter results"-->
          <!--                                debounce="300"-->
          <!--                        ></b-form-input>-->
          <search-filter2
              type="estimates"
              v-model="searchFilter"
              :filters="computedSearchFilters"
              :filterValue="filter"
              :limit-length="50"
              :isShowSearIcon="true"
              @change="onSearchFilterChange"
              @updateToken="value => $store.commit('card/setToken', value)"
              @updateTokens="value => $store.commit('card/setTokens', value)"
              :tokenCopy="getterToken"
              :tokensCopy="getterTokens"
              height="41px"
              aria-expanded="false"
              data-toggle="dropdown"
              :isMyltipleOptions="true"
              :isMyltiple="true"
              :optionsLength="7"
          ></search-filter2>
        </div>
        <div
            v-if="(isShopManagerUser || isProductionManagerUser || isEstimatorUser || isPDRTechnicianUser || isCustomerUser) && isStatusActive"
            class="tabs-nav-right-btn tabs-nav-right-btn-position">
          <router-link to="/cards/new" class="btn btn-primary">
            <i class="ti-plus"></i> New Card
          </router-link>
        </div>
        <div class="navbar-nav nav dropdown dropdown-options  show inline" style="margin-left: 15px; bottom: 8px"
             v-if="namesOfHiddenStatuses.length !== 0 && isStatusActive">
          <button type="button"
                  class="btn waves-effect waves-light ex-options-button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true">
            Options
            <span class="dropdown-arrow pull-right"
                  style="box-sizing: border-box; width: 32px; border-left: 1px solid rgba(27,30,56,0.25);">
                                    <i class="fa fa-chevron-down pull-right"></i>
                                </span>
          </button>
          <div class="dropdown-menu dropdown-menu-right animated fadeInUp"
               aria-labelledby="dropdownMenuLink">

            <a class="dropdown-item text-primary p-1 clickable has-child">
              <span>Un-Hide Status</span>
              <div class="dropdown-menu-child" style="padding: 0px; top: -18px; right: 146px;">
                <a v-for="status in namesOfHiddenStatuses" :key="status.id" @click="showStatus(status.id)"
                   class="dropdown-item text-primary p-1 clickable">{{ status.name }}</a>
              </div>
            </a>
          </div>
        </div>
      </template>
    </tabs>
    <vue-context @close='closeContextBoard' ref="rightClickMenuForBoardResult">
      <ul slot-scope="item">
        <li @click="openNewTabCard">Open in New Tab</li>
        <li @click="openNewBrowserCard">Open in New Window</li>
        <li @click='copyCardModal(item.data.cardId, item.data.cardNumber)'>Copy Card</li>
      </ul>
    </vue-context>

    <modal-wizard ref="copyCardModal" cancelButtonText="Cancel" finishButtonText="Create"
                  @on-complete="completeCopyCard" class="copy-card-modal">
      <modal-wizard-tab title="Copy Card" description="" title-icon-class="ti-align-right">
        <div class="copy-card-modal-text">Do you want to Copy this Card {{cardNumberForCopy}} and all of its details?
        </div>
        <div class="copy-card-modal-block">
          <div class="copy-card-modal-block-top">
            <div class="col-md-6">
              <span class="">Create a New Card & Estimate</span>
            </div>
            <div class="col-md-4">
              <label class="form-check-inline checkbox checkbox-v">
                <input v-model="copyCard.isNew" class="form-check-input" type="checkbox"/>
                <span class="icon"><i class='bx bx-check'></i></span>
              </label>
            </div>

          </div>
          <span class="copy-card-modal-text-middle">or</span>
          <div class="copy-card-modal-block-top">
            <div class="col-md-6">
              <span class="">Select an existing Card & Estimate to Copy {{cardNumberForCopy}} into</span>
            </div>
            <div class="col-md-5">
              <multiselect
                  v-model="copyCard.copyToCard"
                  :options="computedCardsForCopyCard"
                  :showLabels="false"
                  :option-height="29"
                  :max-height="203"
                  :close-on-select="true"
                  placeholder="Select Card Number"
                  track-by="card_id"
                  label="card_number"
                  :disabled="copyCard.isNew"
              />
            </div>
          </div>
          <span class="copy-card-modal-text-middle">Optional</span>
          <div class="copy-card-modal-block-top">
            <div class="col-md-6 copy-card-modal-block copy-card-text-align">
              <span class="">Copy Images</span>
            </div>
            <div class="col-md-4 copy-card-modal-block">
              <label class="form-check-inline checkbox checkbox-v">
                <input v-model="copyCard.isCopyImages" class="form-check-input" type="checkbox"/>
                <span class="icon"><i class='bx bx-check'></i></span>
              </label>
            </div>
          </div>
          <div class="copy-card-modal-block-top">
            <div class="col-md-6 copy-card-modal-block copy-card-text-align">
              <span class="">Copy Files</span>
            </div>
            <div class="col-md-4 copy-card-modal-block">
              <label class="form-check-inline checkbox checkbox-v">
                <input v-model="copyCard.isCopyFiles" class="form-check-input" type="checkbox"/>
                <span class="icon"><i class='bx bx-check'></i></span>
              </label>
            </div>
          </div>
        </div>
      </modal-wizard-tab>
    </modal-wizard>

    <modal-wizard
        id="declineORMJob"
        ref="declineORMJob"
        cancelButtonText="Cancel"
        finishButtonText="Ok"
        :finishButtonTooltip="isDisabledModalFinishButton ? 'Please fill in all fields' : ''"
        :isDisabledFinishButton="isDisabledModalFinishButton"
        @on-close="locationForDecline = ''; reasonForDeclineORM = '';"
        @on-complete="declineORMJob">
      <modal-wizard-tab title="Decline ORM Job" description="" title-icon-class="ti-align-right" class="pr-2">
        <template slot-scope="props">
          <div class="pt-2">Are you sure you want Decline this Job?</div>
          <div class="form-group row">
            <label class="col-form-label"></label>
            <div class="col-12">
              <textarea v-model="reasonForDeclineORM" style="height: 67px; resize: none" class="form-control" placeholder="Please add a reason for declining the repair"/>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label"></label>
            <div class="col-12">
              <multiselect
                  :options="['With me the Repairer', 'Returned to the Customer', 'RMC']"
                  :showLabels="false"
                  v-model="locationForDecline"
                  :option-height="29"
                  :max-height="203"
                  :close-on-select="true"
                  placeholder="Please confirm the current location of the vehicle">
              </multiselect>
            </div>
          </div>
        </template>
      </modal-wizard-tab>
    </modal-wizard>

    <modal-wizard
        ref="markAsModal"
        cancelButtonText="Close"
        finishButtonText="Ok"
        @on-complete="completeMarkAs"
    >
      <modal-wizard-tab
          :title="modal.title"
          description
          title-icon-class="ti-align-right"
          :tabData="modal"
      >
        <template slot-scope="props">
          <div>{{ props.text }}</div>
          <div class="mark-as">
            <div class="row">
              <div class="col-md-4 estimate-number">Estimate Number:</div>
              <div class="col-md-5">{{ props.estimate_number }}</div>
            </div>
            <div class="row" v-if="props.supplement_numbers != ''">
              <div class="col-md-4 estimate-number">Supplement Number(s):</div>
              <div class="col-md-5">{{ props.supplement_numbers }}</div>
            </div>
          </div>
        </template>
      </modal-wizard-tab>
    </modal-wizard>

  </div>
</template>

<script>
import CardsBoardColumns from './cards-board-columns'
import {VueContext} from "vue-context";
import SearchFilter2 from '../../search-filter/search-filter-2'
import _ from "lodash";
import Multiselect from 'vue-multiselect';
import Axios from "axios";
import {mapGetters} from "vuex";
import CardsMixins from '../cards-mixins'

export default {
  components: {
    CardsBoardColumns,
    VueContext,
    SearchFilter2,
    Multiselect
  },
  mixins: [CardsMixins],
  name: "cards-board",
  props: {
    categories: {
      type: Array,
      default() {
        return []
      }
    },
  },
  data () {
    return {
    }
  },
  created() {
    this.debounceChangeUpdateStatus = _.debounce(this.changeUpdateStatus, 1000);
  },
  methods: {
    deleteCard(cardId) {
      NProgress.start();
      const index = this.cardsAll.findIndex(card => {
        return card.card_id === cardId;
      });
      if (index !== -1) {
        //
        let update = {};
        let self = this;
        let uid = Math.random().toString(36).substr(2, 9) + Date.now();
        update[uid] = {};
        update[uid].delete = cardId;
        Axios({
          method: "post",
          responseType: "json",
          headers: {Autosave: true},
          url: `/fe/card/${cardId}`,
          validateStatus: function (status) {
            return status < 500;
          },
          data: update,
        })
            .then((response) => {
              self.cardsAll.splice(index, 1);
              NProgress.done();
            })
            .catch((error) => {
            });
      }
      NProgress.done();
    },
    setViewTable: function () {
      this.$storage.set("views.cards.defaultView", "table-view");
      this.$emit('switch', 'table-view')
    },
    openMenuForBoardCards(event, title, cardId, cardNumber) {
      if (!this.isStatusActive || this.isDisplayBoardUser) {
        return
      }
      let rows = document.getElementsByClassName('board-item');
      _.forEach(rows, (elem) => {
        elem.style.backgroundColor = "";
        elem.style.border = "2px solid #bebebe";
      })

      let boardItem = this.getBoardItem(event);
      if (boardItem){
        this.$nextTick(() => {
          boardItem.style.backgroundColor = "#F3F3F3";
          boardItem.style.borderColor = "#5e77fc";
        })
      }
      this.openMenuBoardResult(event, title, cardId, cardNumber);
    },
    getBoardItem(event) {
      if (!event) {
        return;
      }
      return _.find(document.elementsFromPoint(event.clientX, event.clientY), (el) => {
        return _.includes(el.classList, 'board-item')
      })

    },
    autoUpdateCardBoardSorting(cardIds, statusId, cardId) {
      Axios.post('/fe/cards/updates', {
        boardSort: cardIds,
        status:{
          statusId: statusId,
          cardId: cardId
        }
      })
          .finally(() => {
            this.debounceChangeUpdateStatus()
          })
    },
    changeUpdateStatus() {
      this.$store.commit('changeUpdateStatus', true)
    },
    isDraggedAll(v, ref) {

      let evt = v.evt
      let id = Number(evt.item.getAttribute("data-itemArray"))
      let card_info = _.find(this.cardsAll, (e) => Number(e.card_id) === id)

      //console.log('card_info', card_info);
      // card_info[0].card_status = evt.to.getAttribute("data-card-status-id");
      // card_info[0].status_name = evt.to.getAttribute("data-status-name");

      // this.$store.commit('card/changeCardStatusId', {
      //   cardId: card_info?.card_id,
      //   cardStatus: evt.to.getAttribute("data-card-status-id"),
      //   statusName: evt.to.getAttribute("data-status-name"),
      // })

      if (evt.to.getAttribute("data-card-status-id") == evt.from.getAttribute("data-card-status-id")) {
        _.forEach(v.ids, (i, ind) => {
          let card = _.find(this.cardsAll, (c) => {
            return Number(c.card_id) == Number(i)
          })
          if (card) {
            this.$store.commit('card/changeCardBoardSort', {
              cardId: card.card_id,
              boardSort: ind,
            })
          }
        })
        this.autoUpdateCardBoardSorting(v.ids, evt.to.getAttribute("data-card-status-id"), id)
      } else {
        this.$store.commit('card/changeCardStatusId', {
          cardId: card_info?.card_id,
          cardStatus: evt.to.getAttribute("data-card-status-id"),
          statusName: evt.to.getAttribute("data-status-name"),
        })

        _.forEach(v.ids, (i, ind) => {
          let card = _.find(this.cardsAll, (c) => {
            return Number(c.card_id) == Number(i)
          })
          if (card) {
            this.$store.commit('card/changeCardBoardSort', {
              cardId: card.card_id,
              boardSort: ind,
            })
          }
        })

        _.forEach(this.$refs?.[ref]?.getIds(evt.to.getAttribute("data-card-status-id")), (i, ind) => {
          let card = _.find(this.cardsAll, (c) => {
            return Number(c.card_id) == Number(i)
          })
          if (card) {
            this.$store.commit('card/changeCardBoardSort', {
              cardId: card.card_id,
              boardSort: ind,
            })
          }
        })
        // this.autoUpdateCardStatus(card_info);
        this.autoUpdateCardBoardSorting(this.$refs?.[ref]?.getIds(evt.to.getAttribute("data-card-status-id")), evt.to.getAttribute("data-card-status-id"), id )
      }
      //  this.getRef();

    },
    autoUpdateCardStatus(card_data) {
      //let uid = Math.random().toString(36).substr(2, 9) + Date.now();
      if (!this.updateData.uid) {
        this.updateData.uid = {};
      }
      this.updateData.uid.card_status = card_data[0].card_status;
      this.card_drag_id = card_data[0].card_id;
      this.isAutoSave = true;
      this.beforeDebouncedAutoSave();

      // this.updateCardProgressBar();
    },
    showStatus(id) {
      NProgress.start();
      Axios({
        method: 'post',
        responseType: 'json',
        url: '/fe/cards/kanban/show-status',
        validateStatus: function (status) {
          return status < 500;
        },
        data: {
          statusId: id,
        },
      }).then(response => {
        if (response.status === 200 && response.data._status) {
          this.$store.commit('card/updateUserHiddenStatuses', response.data.userHiddenStatuses);
          this.$store.commit('card/updateVendorHiddenStatuses', response.data.vendorHiddenStatuses);
        }
      })
          .finally(() => {
            NProgress.done();
          });
    },
    closeContextBoard() {
      let rows = document.getElementsByClassName('board-item');
      _.forEach(rows, (elem) => {
        elem.style.backgroundColor = "";
        elem.style.border = "2px solid #bebebe";
      })
    },
    openMenuBoardResult: function (e, title, cardId, cardNumber) {
      if (!this.isStatusActive || this.isDisplayBoardUser) {
        return
      }
      this.openedNewTabCardId = cardId;
      this.$refs.rightClickMenuForBoardResult.open(e, {item: title, cardId: cardId, cardNumber: cardNumber});
    },
    isCheckedByFilter(c, f) {
      if (f == this.$route.params.filter) {
        return !!_.find(this.$route.params.items, (itm) => {
          return Number(itm) == Number(c.card_id)
        })
      }
      let filtersFromRoute = []
      if (this.$route.params && !_.isEmpty(this.$route.params.filters)) {
        filtersFromRoute = this.$route.params.filters;
      }
      if (this.$route.query && !_.isEmpty(this.$route.query.filters)) {
        filtersFromRoute = _.isArray(this.$route.query.filters) ? this.$route.query.filters : [this.$route.query.filters];
      }
      if (((f == this.$route.params.trackerFilter || f == this.$route.query.trackerFilter) && !_.isEmpty(filtersFromRoute))) {
        return !!_.find(filtersFromRoute, (id) => Number(id) == Number(c.card_id))
      }
      let filter = _.trim(_.toLower(f))
      if (_.isEmpty(filter)) {
        return true
      }
      if (_.includes(_.trim(_.toLower(c.card_number)), filter)) {
        return true
      }
      if (_.includes(_.trim(_.toLower(c.rego_number)), filter)) {
        return true
      }
      if (this.isCanSearchBySomeFields && _.includes(_.trim(_.toLower(c.estimateNumber)), filter)) {
        return true
      }
      if (_.includes(_.trim(_.toLower(c.make)), filter)) {
        return true
      }
      if (_.includes(_.trim(_.toLower(c.model)), filter)) {
        return true
      }
      // if (_.includes(_.trim(_.toLower(c.priority)), filter)) {
      //   return true
      // }
      if (this.isCanSearchBySomeFields && _.includes(_.trim(_.toLower(c.insurance)), filter)) {
        return true
      }
      // if (_.includes(_.trim(_.toLower(c.colour)), filter)) {
      //   return true
      // }
      if (_.includes(_.trim(_.toLower(c.customer_name)), filter)) {
        return true
      }
      // if (_.includes(_.trim(_.toLower(c.card_id)), filter)) {
      //   return true
      // }
      // if (_.includes(_.trim(_.toLower(c.partNumbers)), filter)) {
      //   return true
      // }
      // if (_.includes(_.trim(_.toLower(c.filters)), filter)) {
      //   return true
      // }
      if (c.claim_number && _.includes(_.trim(_.toLower(c.claim_number)), filter)) {
        return true
      }
      return false
    },
    boardTabChanged(selectedTab) {
      this.$storage.set("cards.tabs.defaultCardsBoard", selectedTab.tab.id);
    },
  },
  computed: {
    ...mapGetters({
      hiddenStatuses: 'card/hiddenStatuses',
      ceStatuses: 'ceStatuses',
    }),
    namesOfHiddenStatuses() {
      let names = [];
      let self = this;
      _.forEach(this.hiddenStatuses.userHiddenStatuses, function (id) {
        let ceStatus = _.filter(self.ceStatuses, function (status) {
          return status.ce_status_id == id;
        });
        if (ceStatus && ceStatus[0]) {
          names.push({
            name: ceStatus[0].name,
            id: ceStatus[0].ce_status_id,
          });
        }
      });
      _.forEach(this.hiddenStatuses.vendorHiddenStatuses, function (id) {
        let ceStatus = _.filter(self.ceStatuses, function (status) {
          return status.ce_status_id == id;
        });
        if (ceStatus && ceStatus[0]) {
          names.push({
            name: ceStatus[0].name,
            id: ceStatus[0].ce_status_id,
          });
        }
      });
      return _.uniqBy(names, 'id');
    },

    computedDefaultCardBoardTabHash: function () {
      if (this.$storage.get("cards.tabs.defaultCardsBoard")) {
        return this.$storage.get("cards.tabs.defaultCardsBoard");
      }
      return "cards-all-board";
    },
    computedCardsAllForTable() {
      if (this.isHasNotFilters) {
        return this.cardsAll
      }
      return this.filterCards(this.cardsAll)
    },
    computedCardsAll() {
      let cards = this.computedCardsAllForTable
      if (this.filter) {
        return _.filter(cards, (c) => {
          return this.isCheckedByFilter(c, this.filter)
        })
      }
      return cards
    },
    computedOpenCards() {
      return _.filter(this.computedCardsAll, (c) => {
        return Number(c.state) == 0
      })
    },
    computedActiveCards() {
      return _.filter(this.computedCardsAll, (c) => {
        return Number(c.state) !== 3 && Number(c.state) !== 0
      })
    },
    computedClosedCards() {
      return _.filter(this.computedCardsAll, (c) => {
        return Number(c.state) == 3
      })
    },
  }
}
</script>

<style scoped>
.tabs-nav-right-btn-position{
  position: relative;
  bottom: 8px;
}
.tabs-nav-right-search .search-filter >>> .form-control:focus{
  outline: 0 !important;
}
.tabs-nav-right-search .search-filter >>> .search-filter__input{
  font-size: 13px !important;
  font-weight: 800;
  color: black;
}
.tabs-nav-right-search .search-filter >>> .search-filter__box{
  border: 3px solid #5e77fd;
  border-radius: 7px;
}
.tabs-nav-right-search .search-filter >>> .form-control:focus{
  outline: 0 !important;
}
.tabs-nav-right-search .search-filter >>> .search-filter__input{
  font-size: 13px !important;
  font-weight: 800;
  color: black;
}
.tabs-nav-right-search .search-filter >>> .search-filter__box{
  border: 3px solid #5e77fd;
  border-radius: 7px;
}
.search-filter >>> input::placeholder{
  color: #bbbbc2;
}
.search-filter >>> .search-filter__box__focused{
  border: 3px solid rgba(94, 121, 255, 0.5) !important;
  box-shadow: 0 0 0 0.2rem rgb(94 121 255 / 25%) !important;
}
.search-filter >>> .search-filter__li--input{
  border-bottom: 0;
}
.search-filter >>> .search-filter__li{
  border-bottom: 0 !important;
}
.search-filter >>> .search-filter__box{
  border: 2px solid rgba(28, 31, 57, 0.25);
}
.tabs-nav-right-search-font-weight >>> input{
  font-weight: 700;
}
.tabs-nav-right-search-font-weight >>> input::placeholder{
  font-weight: 600;
}

.cards >>> .tabs-component{
  touch-action: none;
}

.cards >>> .board-item-holder .board-item{
  border: 2px solid #bebebe;
}

.cards >>> .board-item-holder .board-item:hover{
  border-color: #5e77fc;
}



</style>

