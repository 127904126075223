<script>
    import imageEditor from "./image-editor";
    import {isMobile} from '../../../deviceDetect/index';
    import AdvertBanner from '../../utility/advert-banner';
    import {mapGetters} from "vuex";
    import VueZoomer from 'vue-zoomer'

    export default {
        name: "image-gallery",
        props: {
            images: {
                type: Array,
            },
            imageList: {
                type: Object,
            },
            category: {
                type: String
            },
            currentIndex: {
                type: Number
            },
            useEditor: {
                type: Boolean
            },
            showRemove: {
                type: Boolean,
                default: true
            },
          isShowBigImage: {
                type: Boolean,
                default: false
            },
          isFromEstimate: {
                type: Boolean,
                default: false
            },
          isFromCard: {
                type: Boolean,
                default: false
            },
            stopBannerRotation: {
                type: Boolean,
                default: true
            },
        },
        data: function (th) {
            return {
                openMobileZoom: false,
                isImageHorizontal: false,
                loading: false,
                cancelTimeout: null,
                isEditing: false,
                selectedIndex: this.currentIndex,
                isMobile,
                zoomScale: 1,
                zoomed: false,
            };
        },
        watch: {
            selectedIndex(newIndex) {
                this.$emit('change', newIndex);
            },
        },
        computed: {
            ...mapGetters({
              isStatusActive: 'isStatusActive',
            }),
          computedIsShowBigImage() {
            return (this.isShowBigImage || (!this.isShowBigImage && (this.isFromEstimate || this.isFromCard))) && !this.isMobile
          },
          computedImages() {
            let limit = 5
            if (this.images.length == limit) {
              return this.images
            }
            let indexStart = this.selectedIndex - limit
            let indexStart2 = null

            let indexEnd = this.selectedIndex + limit
            let indexEnd2 = null

            if (indexStart < 0) {
              indexStart = 0
              indexStart2 = this.selectedIndex - limit
            }
            if (indexEnd + 1 > this.images.length) {
              indexEnd = this.images.length - 1
              indexEnd2 = (this.selectedIndex + limit) - this.images.length
            }

            let indexes = []

            for (let i = indexStart; i <= indexEnd; i++) {
              indexes.push(i)
            }

            if (indexStart2 != null) {
              for (let i = this.images.length + indexStart2; i <= this.images.length - 1; i++) {
                indexes.push(i)
              }
            }

            if (indexEnd2 != null) {
              for (let i = 0; i <= indexEnd2; i++) {
                indexes.push(i)
              }
            }

            indexes = _.uniq(indexes)
            return _.filter(this.images, (itm, ind) => {
              return _.includes(indexes, ind)
            })
          },
            isTechnicalUser: function () {
                let userInfo = this.$store.state.userInfo;
                if (userInfo && userInfo.group_id == 4) {
                    return true;
                }
                return false;
            },
            currentNo: function () {
                return this.selectedIndex + 1;
            },
            countImages: function () {
                return this.images ? this.images.length : 0;
            },
            currentImageUrl: function () {
                return this.images &&
                this.selectedIndex >= 0 &&
                this.selectedIndex < this.countImages
                    ? this.images[this.selectedIndex].url
                    : "";
            },
          currentImageUrlWithoutNumber: function () {
            return this.images &&
            this.selectedIndex >= 0 &&
            this.selectedIndex < this.countImages
                ? this.getOriginalUrl(this.images[this.selectedIndex].url)
                : "";
          },
            currentImageId: function () {
                return this.images &&
                this.selectedIndex >= 0 &&
                this.selectedIndex < this.countImages
                    ? this.images[this.selectedIndex].id
                    : 0;
            },
            nextImageUrl: function () {
                window.scrollTo(0,0);
                const idx = this.nextIndex;
                return idx >= 0 ? this.images[idx].url : "";
            },
            prevImageUrl: function () {
                window.scrollTo(0,0);
                const idx = this.prevIndex;
                return idx >= 0 ? this.images[idx].url : "";
            },
            nextIndex: function () {
                if (this.countImages > 0) {
                    return this.selectedIndex < this.countImages - 1
                        ? this.selectedIndex + 1
                        : 0;
                } else {
                    return -1;
                }
            },
            prevIndex: function () {
                if (this.countImages > 0) {
                    return this.selectedIndex > 0
                        ? this.selectedIndex - 1
                        : this.countImages - 1;
                } else {
                    return -1;
                }
            },
            disableZoom() {
                return this.zoomScale === 1;
            }
        },
        methods: {
          getOriginalUrl(v) {
            if (!_.includes(v, '?o=1')) {
              if (_.includes(v, '?at=')) {
                v = v.replace('?at=', '?o=1&at=')
                return v
              }
              return v + '?o=1&at=' + localStorage.getItem('token')
            }
            return v
          },
          getCategory(id) {
            let category = null
            _.forIn(this.imageList, (itm, cat) => {
              let img = _.find(itm, (i) => {
                return Number(id) == Number(i.id)
              })
              if (img) {
                category = cat
              }
            })
            return category
          },
          setIndex(v) {
            this.selectedIndex = v
          },
            onClickCloseMobileZoom() {
              $('body').removeClass('modal-open')
              this.openMobileZoom = false
            },
            onClickClose: function () {
                if (this.isEditing) {
                    this.isEditing = false;
                } else {
                  if (this.isShowBigImage) {
                    window.close()
                  } else {
                    this.$emit("close-gallery");
                  }
                }
            },
            onClickNext: function () {
                if (this.countImages > 1) {
                    this.selectedIndex = this.nextIndex;
                    this.isEditing = false;
                    this.onImageLoadStart();
                }
                if (!isMobile && !this.computedIsShowBigImage){
                  this.$refs.nextAdvertBanner.changeBannerForRotation()
                }
                if(this.zoomed == true){
                  this.$refs.zoomer.reset()
                }
            },
            onClickPrev: function () {
                if (this.countImages > 1) {
                    this.selectedIndex = this.prevIndex;
                    this.isEditing = false;
                    this.onImageLoadStart();
                }
                if (!isMobile && !this.computedIsShowBigImage){
                  this.$refs.nextAdvertBanner.changeBannerForRotation()
                }
                if(this.zoomed == true){
                  this.$refs.zoomer.reset()
                }
            },
            onClickEdit: function () {
                if (this.useEditor) {
                    this.isEditing = true;
                }
            },
            onClickDownload: function () {
                const imageUrl = this.currentImageUrl;
                const names = imageUrl.split("/");
                const filenames = names.filter(name => (/jpeg|jpg|png|svg/i).test(name));
                const defaultName = this.currentImageId;
                const filename = filenames.length > 0 ? filenames[0] : defaultName;

                this.forceDownload(imageUrl, filename);
            },
            forceDownload: function (url, fileName) {
                let xhr = new XMLHttpRequest();
                xhr.open("GET", url, true);
                xhr.responseType = "blob";
                xhr.onload = function () {
                    var urlCreator = window.URL || window.webkitURL;
                    var imageUrl = urlCreator.createObjectURL(this.response);
                    var tag = document.createElement('a');
                    tag.href = imageUrl;
                    tag.download = fileName;
                    document.body.appendChild(tag);
                    tag.click();
                    document.body.removeChild(tag);
                };
                xhr.send();
            },
            onImageLoadStart: function () {
              this.cancelTimeout = setTimeout(() => {
                if (this.cancelTimeout) {
                  // this.loading = true;
                }
              }, 300);
            },
            onImageLoaded: function () {
              this.loading = false;
              if (this.cancelTimeout) {
                clearTimeout(this.cancelTimeout);
                this.cancelTimeout = null;
              }
              setTimeout(() => {
                let ref = 'zoomImage' + this.images[this.selectedIndex].id
                if(this.$refs[ref]?.[0]?.$el && this.$refs[ref][0].$el.clientHeight <= this.$refs[ref][0].$el.clientWidth){
                  this.isImageHorizontal = true
                } else {
                  this.isImageHorizontal = false
                }
              }, 50);
            },
            onImageSaved: function (v) {
                this.$emit("saved-image", v);
                const img = new Image();
                img.src =
                    this.images[this.selectedIndex].url +
                    "&version=" +
                    new Date().getTime().toString();
            },
            onImageZoomIn() {
                if (this.zoomScale < 2) {
                    this.zoomScale += 0.5;
                } else {
                    this.zoomScale = 2;
                }

                // manually change the component scale
              let ref = 'zoomImage' + this.images[this.selectedIndex].id
                const zoomImage = this.$refs[ref][0];
                // only normal and 2 scale
                if (this.zoomScale === 2) {
                    zoomImage.scaleFactor = parseInt(this.zoomScale);
                    zoomImage.$refs.zoom.style.transform = `scale(${this.zoomScale})`;
                } else {
                    zoomImage.scaleFactor = 1;
                    zoomImage.$refs.zoom.style.transform = ``;
                }
                zoomImage.initEventResized();

            },
            onImageZoomOut() {
                if (this.zoomScale > 1 && this.zoomScale <= 2) {
                    this.zoomScale -= 0.5;
                }

                // manually change the component scale
              let ref = 'zoomImage' + this.images[this.selectedIndex].id
                const zoomImage = this.$refs[ref][0];
                // only normal and 2 scale
                if (this.zoomScale === 2) {
                    zoomImage.scaleFactor = parseInt(this.zoomScale);
                    zoomImage.$refs.zoom.style.transform = `scale(${this.zoomScale})`;
                } else {
                    zoomImage.scaleFactor = 1;
                    zoomImage.$refs.zoom.style.transform = ``;
                }
                zoomImage.initEventResized();
            },
            isZoomWindow() {
              if(isMobile){
                $('body').addClass('modal-open')
                this.openMobileZoom = true
              }
            },
        },
        components: {
            "image-edditor": imageEditor,
            AdvertBanner,
            VZoomer: VueZoomer.Zoomer,
        },
    };
</script>

<template>
    <div :class="{'image-view-2': computedIsShowBigImage, 'image-view-1':!computedIsShowBigImage}">
      <div class="mobile-window-zoom"
           v-if="openMobileZoom">
        <div class="mobile-navigation" style="top: 15px; right: 10px">
          <button
              v-if="!loading"
              title="Delete"
              type="button"
              style="position: relative; bottom: 9px"
              class="mobile-navigation-btn"
              @click="$emit('removeImage', {key: getCategory(currentImageId), id: currentImageId})">
            <i class='bx bx-trash'></i>
          </button>
          <button
              v-if="!loading"
              title="Close (Esc)"
              type="button"
              style="font-size: 30px;"
              class="mobile-navigation-btn"
              @click.stop="onClickCloseMobileZoom">
            <i class="bx bx-x" style="position: relative; bottom: 4px; right: 1px"></i>
          </button>
        </div>
        <v-zoomer
            ref="zoomer"
            :maxScale="5"
            :minScale="1"
            style="object-fit: contain; width: 100vw; height: 100vh"
            :zooming-elastic="false"
            :zoomed.sync="zoomed"
        >
          <img
              style="object-fit: contain; width: 100%; height: 100%;"
              :src="currentImageUrl" alt="">
        </v-zoomer>
        <div>
          <button
              style="top: 53%; left: 10px; background: whitesmoke !important;"
              v-if="countImages > 1 && !isEditing"
              title="Previous (Left arrow key)"
              type="button"
              @click="onClickPrev"
              class="gallery-arrow gallery-arrow-left gallery-prevent-close"
          >
            <i @click="onImageLoaded" class="bx bx-chevron-left"></i>
          </button>
          <button
              style="top: 53%; right: 10px; background: whitesmoke !important;"
              v-if="countImages > 1 && !isEditing"
              title="Next (Right arrow key)"
              type="button"
              @click="onClickNext"
              class="gallery-arrow gallery-arrow-right gallery-prevent-close"
          >
            <i @click="onImageLoaded" class="bx bx-chevron-right"></i>
          </button>
        </div>
      </div>
      <div v-if="computedIsShowBigImage" class="open-img-new-window">
        <div class="gallery-figure-new-window">
          <div class=""
               v-for="img in computedImages"
               :class="{'hidden-img': img.url !== currentImageUrl}"
               :key="img.id">

            <image-edditor v-if="isEditing && img.id == currentImageId"
                           :image-id="currentImageId"
                           :image-url="currentImageUrlWithoutNumber"
                           :category="getCategory(img.id)"
                           style="margin: 0 auto"
                           :images="images"
                           :newWindowEditor="true"
                           :isFromEstimate="isFromEstimate"
                           :isFromCard="isFromCard"
                           @saved-image="onImageSaved"
            ></image-edditor>
            <figure v-else>
              <div class="gallery-img">
                <div>
                  <v-zoomer
                      ref="zoomer"
                      :maxScale="5"
                      :minScale="1"
                      style="object-fit: contain; width: 100vw; height: calc(100vh - 60px)"
                      :zooming-elastic="false"
                  >
                    <img
                        style="object-fit: contain; width: 100%; height: 100%;"
                        :src="img.url" alt="">
                  </v-zoomer>
                </div>
              </div>
              <figcaption v-if="!loading && !computedIsShowBigImage">
                <div class="gallery-bottom-bar">
                  <div
                      class="gallery-counter"
                      v-if="countImages > 1"
                  >{{currentNo}} of {{countImages}}
                  </div>
                </div>
              </figcaption>
            </figure>
          </div>
        </div>
        <div>
          <button
              style="top: 53%; left: 160px; background: whitesmoke !important;"
              v-if="countImages > 1 && !isEditing"
              title="Previous (Left arrow key)"
              type="button"
              @click="onClickPrev"
              class="gallery-arrow gallery-arrow-left gallery-prevent-close  gallery-arrow-left-open-sidebar"
          >
            <i @click="onImageLoaded" class="bx bx-chevron-left"></i>
          </button>
          <button
              style="top: 53%; right: 80px; background: whitesmoke !important;"
              v-if="countImages > 1 && !isEditing"
              title="Next (Right arrow key)"
              type="button"
              @click="onClickNext"
              class="gallery-arrow gallery-arrow-right gallery-prevent-close"
          >
            <i @click="onImageLoaded" class="bx bx-chevron-right"></i>
          </button>
        </div>
        <div class="download_edit_wrap download_edit_wrap-editor" style="top: 20px; right: 340px;" :class="useEditor && !isMobile ? '' : 'ml-2'">
          <button v-if="!isTechnicalUser && !isEditing"
                  class="btn btn-primary mx-0 waves-effect waves-light"
                  :class="useEditor && !isMobile ? 'mr-1' : 'mr-0'"
                  @click="onClickDownload"
          >Download
          </button>
          <button
              v-if="!isEditing"
              class="btn btn-primary mx-0 waves-effect waves-light sec_btn"
              @click="onClickEdit"
          >Edit
          </button>
        </div>
        <div class="mobile-navigation">
          <button
              v-if="!loading"
              title="Delete"
              type="button"
              style="position: relative; bottom: 3px"
              class="mobile-navigation-btn"
              @click="$emit('removeImage', {key: getCategory(currentImageId), id: currentImageId})">
            <i class='bx bx-trash'></i>
          </button>
          <button
              v-if="!loading"
              title="Close (Esc)"
              type="button"
              style="font-size: 30px; text-align: center"
              class="mobile-navigation-btn"
              @click.stop="onClickClose">
            <i class="bx bx-x" style="position: relative; top: 2px;"></i>
          </button>
        </div>
      </div>
      <div class="gallery-bg gallery-img-mobile gallery-ready" style="margin-top: 30px;"></div>
        <div class="gallery-wrap gallery-gallery gallery-close-btn-in gallery-auto-cursor gallery-img-mobile gallery-ready"
             :class="{'horizontal-image': isImageHorizontal}"
             tabindex="-1">
            <div class="gallery-container gallery-image-holder gallery-s-ready"
                 :class="isEditing ? 'is-editing' : ''"
                 style="margin-top: 30px;">
                <div class="gallery-content" :class="loading ? 'additional-image-height' : ''">
                    <div class="download_edit_wrap" :class="useEditor && !isMobile ? '' : 'ml-2'">
                        <button v-if="!isTechnicalUser && !isEditing"
                                class="btn btn-primary mx-0 waves-effect waves-light"
                                :class="useEditor && !isMobile ? 'mr-1' : 'mr-0'"
                                @click="onClickDownload"
                        >Download
                        </button>
                        <button
                                v-if="useEditor && !isMobile && isStatusActive && !isEditing"
                                class="btn btn-primary mx-0 waves-effect waves-light sec_btn"
                                @click="onClickEdit"
                        >Edit
                        </button>
                    </div>
                    <button
                            v-if="!loading && !isMobile"
                            title="Close (Esc)"
                            type="button"
                            class="gallery-close gallery-close-desktop"
                            @click.stop="onClickClose">

                        <i class="bx bx-x"></i>
                    </button>
                    <div class="gallery-figure" id="wrapperSize">
                      <div class=""
                           v-for="img in computedImages"
                           :class="{'hidden-img': img.url !== currentImageUrl}"
                           :key="img.id">

                        <image-edditor v-if="isEditing && img.id == currentImageId"
                                       :image-id="currentImageId"
                                       :image-url="currentImageUrlWithoutNumber"
                                       :images="images"
                                       @saved-image="onImageSaved"
                                       :category="getCategory(img.id)"
                        ></image-edditor>
                        <figure v-else>
                          <div class="gallery-img">
                            <div  v-if="!computedIsShowBigImage" class="" @click="isZoomWindow">
                              <zoom-on-hover
                                  v-if="!computedIsShowBigImage"
                                  :ref="'zoomImage' + img.id"
                                  v-show="!loading"
                                  style="width: 100%; height: 100%; object-fit: scale-down; object-position: right;"
                                  :imgNormal="img.url"
                                  @loaded="onImageLoaded"
                                  @loadstart="onImageLoadStart"
                                  :scale="zoomScale"
                                  :disabled="disableZoom"
                              />
                            </div>

                                <button v-if="!computedIsShowBigImage && showRemove && isStatusActive && !isMobile" @click="$emit('removeImage', {key: getCategory(currentImageId), id: currentImageId})"
                                        type="button"
                                        v-b-tooltip.hover="{customClass: 'ps-tooltip delete-image-tooltip', title:'Delete', placement:'bottomleft', delay: { show: 100, hide: 20000 }}"
                                        class="gallery-delete">
                                    <svg width="18px" height="20px" viewBox="0 0 18 20" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                         xmlns:xlink="http://www.w3.org/1999/xlink" style="pointer-events: none">
                                        <!-- Generator: sketchtool 60.1 (101010) - https://sketch.com -->
                                        <title>EFD4E02B-E531-48FD-903C-7C1EC7568772</title>
                                        <desc>Created with sketchtool.</desc>
                                        <g id="UI-Design" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g id="Dashboard-Redesign-(Detail-Cards)Files" transform="translate(-1264.000000, -933.000000)"
                                               fill="#FF5E5E" fill-rule="nonzero">
                                                <g id="Group-4" transform="translate(265.000000, 933.000000)">
                                                    <g id="trash" transform="translate(999.000000, 0.000000)">
                                                        <path
                                                                d="M6,2 L6,1 C6,0.44771525 6.44771525,0 7,0 L11,0 C11.5522847,0 12,0.44771525 12,1 L12,2 L16,2 C17.1045695,2 18,2.8954305 18,4 L18,5 C18,6.1045695 17.1045695,7 16,7 L15.867,7 L15.187,17.2 C15.081727,18.775582 13.773095,20 12.194,20 L5.826,20 C4.24834545,20 2.94028051,18.7780028 2.833,17.204 L2.137,7 L2,7 C0.8954305,7 0,6.1045695 0,5 L0,4 C0,2.8954305 0.8954305,2 2,2 L6,2 Z M16,4 L2,4 L2,5 L16,5 L16,4 Z M4.141,7 L4.828,17.068 C4.86376888,17.5927957 5.29998676,18.0001655 5.826,18 L12.194,18 C12.7207338,18 13.1572356,17.5915853 13.192,17.066 L13.862,7 L4.142,7 L4.141,7 Z M7,8 C7.55228475,8 8,8.44771525 8,9 L8,16 C8,16.5522847 7.55228475,17 7,17 C6.44771525,17 6,16.5522847 6,16 L6,9 C6,8.44771525 6.44771525,8 7,8 Z M11,8 C11.5522847,8 12,8.44771525 12,9 L12,16 C12,16.5522847 11.5522847,17 11,17 C10.4477153,17 10,16.5522847 10,16 L10,9 C10,8.44771525 10.4477153,8 11,8 Z"
                                                                id="Shape"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </button>
                                <div class="mobile-navigation mobile-navigation-portrait" v-if="isMobile">
                                  <button
                                      style="position: relative; bottom: 4px"
                                      v-if="!loading"
                                      title="Delete"
                                      type="button"
                                      class="mobile-navigation-btn"
                                      @click="$emit('removeImage', {key: getCategory(currentImageId), id: currentImageId})">
                                    <i class='bx bx-trash'></i>
                                  </button>
                                  <button
                                      style="font-size: 30px"
                                      v-if="!loading"
                                      title="Close (Esc)"
                                      type="button"
                                      class="mobile-navigation-btn"
                                      @click.stop="onClickClose">
                                    <i class="bx bx-x"></i>
                                  </button>
                                </div>

                                <!-- zoom actions -->
                                <div class="actions actions--topleft" v-if="!isMobile">
                                    <button class="actions__item" :class="{'actions__item--active': !disableZoom}" @click.prevent="onImageZoomIn">
                                        <i class='bx bx-zoom-in'></i>
                                        <div v-if="false && !disableZoom" class="actions__zoom-info-circle">{{zoomScale}}x</div>
                                    </button>
                                    <button class="actions__item" @click.prevent="onImageZoomOut">
                                        <i class='bx bx-zoom-out'></i>
                                    </button>
                                    <transition name="slide-fade">
                                        <div v-if="!disableZoom" class="actions__info">
                                            {{ zoomScale === 2 ? 'Double Zoom' : 'Normal Zoom' }}
                                        </div>
                                    </transition>
                                </div>
                                <div class="gallery-arrow-mobile">
                                  <button
                                      v-if="countImages > 1 && !isEditing"
                                      title="Previous (Left arrow key)"
                                      type="button"
                                      @click="onClickPrev"
                                      class="gallery-arrow gallery-arrow-left gallery-prevent-close"
                                  >
                                    <i @click="onImageLoaded" class="bx bx-chevron-left"></i>
                                  </button>
                                  <button
                                      v-if="countImages > 1 && !isEditing"
                                      title="Next (Right arrow key)"
                                      type="button"
                                      @click="onClickNext"
                                      class="gallery-arrow gallery-arrow-right gallery-prevent-close"
                                  >
                                    <i @click="onImageLoaded" class="bx bx-chevron-right"></i>
                                  </button>
                                </div>
                            </div>
                            <!--                style="max-height: 75vh"-->
                            <link rel="preload" :href="nextImageUrl" as="image"/>
                            <link rel="preload" :href="prevImageUrl" as="image"/>
                            <div
                                    v-show="loading"
                                    class="gallery-img"
                                    style="color: white; font-weight: bold"
                            >Loading image {{currentNo}}...
                            </div>
                            <figcaption v-if="!loading && !computedIsShowBigImage">
                                <div class="gallery-bottom-bar">
                                    <div
                                            class="gallery-counter"
                                            v-if="countImages > 1"
                                    >{{currentNo}} of {{countImages}}
                                    </div>
                                </div>
                                <div class="download_edit_wrap-bottom">
                                    <button v-if="!isTechnicalUser && !isEditing"
                                            class="btn btn-primary mx-0 waves-effect waves-light"
                                            :class="useEditor && !isMobile ? 'mr-1' : 'mr-0'"
                                            @click="onClickDownload"
                                    >Download
                                    </button>
                                    <button
                                            style="width: 83px;"
                                            v-if="useEditor && !isMobile && isStatusActive && !isEditing"
                                            class="btn btn-primary mx-0 waves-effect waves-light sec_btn"
                                            @click="onClickEdit"
                                    >Edit
                                    </button>
                                </div>
                            </figcaption>
                        </figure>

                      </div>

                    </div>
                </div>
                <div class="gallery-arrow-min-width">
                  <button
                      v-if="countImages > 1 && !isEditing"
                      title="Previous (Left arrow key)"
                      type="button"
                      @click="onClickPrev"
                      class="gallery-arrow gallery-arrow-left gallery-prevent-close"
                  >
                    <i @click="onImageLoaded" class="bx bx-chevron-left"></i>
                  </button>
                  <button
                      v-if="countImages > 1 && !isEditing"
                      title="Next (Right arrow key)"
                      type="button"
                      @click="onClickNext"
                      class="gallery-arrow gallery-arrow-right gallery-prevent-close"
                  >
                    <i @click="onImageLoaded" class="bx bx-chevron-right"></i>
                  </button>
                </div>
                <div v-if="!computedIsShowBigImage" class="desktop-b-promo-block estimare-image-gallery-b-promo">
                    <advert-banner
                            ref="nextAdvertBanner"
                            v-if="!isMobile"
                            :bannerType="4"
                    />
                </div>
            </div>
        </div>
    </div>
</template>


<style>
.hidden-img {
  visibility: hidden !important;
  position: absolute !important;
  top: -20000px !important;
  height: 1px !important;
  width: 1px !important;
}
</style>
<style scoped>
    /*.gallery-figure-new-window >>> .canvas-container{*/
    /*  position: absolute;*/
    /*  top: 50%;*/
    /*  transform: translate(0, -50%)*/
    /*}*/
    /*.gallery-figure-new-window >>> .canvas-container .lower-canvas{*/
    /*  display: block;*/
    /*  position: relative !important;*/
    /*  margin: 0 auto;*/
    /*}*/
    /*.gallery-figure-new-window >>> .estimate-window{*/
    /*  height: calc(100vh - 100px);*/
    /*}*/
    .compact-sidebar .gallery-arrow-left-open-sidebar{
      left: 10px !important;
    }
    .gallery-figure-new-window >>> .image-editor-color-menu{
      position: absolute;
      right: 40px;
      top: 0;
      height: calc(100vh - 100px);
    }
    .gallery-figure-new-window >>> .image-editor-color-menu-border{
      padding: 5px 5px 0 5px;
      border: 1px solid rgba(128, 128, 128, 0.29);
      background-color: #fdfdfd;
    }
    .gallery-figure-new-window >>> .btn-image-editor-save{
      position: absolute;
      top: 20px;
      right: 440px;
    }

    .gallery-figure-new-window >>> .custom-editor{
      position: relative;
      object-fit: contain;
      width: 100%;
      height: 100%;
      top: -10px !important;
    }
    .gallery-figure-new-window >>> .image-editor-editor-menu{
      position: relative;
      z-index: 9999;
      background-color: #fdfdfd;
      top: 10px;
      height: 30px;
      padding: 0 5px;
      max-width: 300px;
      border: 1px solid rgba(128, 128, 128, 0.29);
    }
    .gallery-figure-new-window >>> .image-editor-editor-menu div{
      margin-top: 4px;
    }
    .gallery-figure-new-window >>> .image-editor-color-menu{
      position: relative;
      z-index: 9999;
      padding: 0 5px;
    }
    .gallery-figure-new-window >>> .image-editor-color-menu div{
      border: 1px solid rgba(128, 128, 128, 0.29);
      background-color: #fdfdfd;
    }
    .mobile-navigation{
      position: absolute;
      top: 5px;
      right: 10px;
      z-index: 999999;
    }
    .mobile-navigation-btn{
      width: 35px;
      height: 35px;
      margin: 0 8px;
      border-radius: 3px;
      background-color: rgba(255, 255, 255, 0.99);
      border: 1px solid rgba(128, 128, 128, 0.29);
      font-size: 22px;
      color: black;
    }
    .mobile-window-zoom{
      height: 100vh;
      width: 100%;
      position: absolute;
      top: 0;
      z-index: 999999;
      left: 0;
      background-color: rgba(0, 0, 0, 0.75);
    }
      .image-view-2 .mobile-navigation{
        position: absolute;
        top: 20px;
        right: 100px;
      }
      .image-view-2 .mobile-navigation-btn{
        width: 50px;
        height: 50px;
        margin: 0 8px;
        border-radius: 3px;
        background-color: rgba(255, 255, 255, 0.99);
        border: 1px solid rgba(128, 128, 128, 0.29);
        font-size: 22px;
        color: black;
      }
    .image-view-2 .open-img-new-window{

      /*height: calc(100vh - 60px);*/
      margin-top: 60px;
      margin-left: 70px;
      width: 100%;
      position: absolute;
      overflow: hidden;
      top: 0;
      z-index: 9999;
      left: 0;

    }
    .image-view-2 .gallery-container{
      display: none;
    }
    .gallery-close.gallery-close-mobile{
      display: none !important;
    }
    .gallery-arrow-mobile{
      display: none;
    }
    .sec_btn,
    .sec_btn:hover {
        background-color: #EEEEEE;
        color: black;
    }

    .sec_btn:hover {
        background-color: white;
    }

    .gallery-bg {
        top: 0;
        /* left: 0; */
        left: 220px;
        width: 100%;
        height: 100%;
        z-index: 1042;
        position: fixed;
        background: #F3F4F5;
    }

    .gallery-wrap {
        position: absolute;
        top: 25px;
        left: 220px;
        z-index: 1043;
        outline: none !important;
        -webkit-backface-visibility: hidden;
        width: calc(99vw - 220px);
    }

    @media screen and (min-width: 1515px)  and (min-height: 1100px) {
      .horizontal-image .gallery-container {
        max-width: 1000px !important;
      }
      .horizontal-image .estimare-image-gallery-b-promo{
        right: 0;
        left: auto;
      }
      .horizontal-image .gallery-arrow{
        top: 30%;
      }
    }
    @media screen and (min-width: 1700px)  and (min-height: 1100px) {
      .horizontal-image .gallery-container {
        max-width: 1200px !important;
      }
      .horizontal-image .desktop-b-promo-block{
        justify-content: start;
      }
      .horizontal-image .estimare-image-gallery-b-promo{
        left: 1440px;
      }
      .horizontal-image .gallery-arrow{
        top: 40%;
      }
    }
    @media screen and (min-width: 1900px)  and (min-height: 1200px) {
      .horizontal-image .gallery-container {
        max-width: 1330px !important;
      }
      .horizontal-image .desktop-b-promo-block{
        justify-content: start;
      }
      .horizontal-image .estimare-image-gallery-b-promo{
        left: 1570px;
      }
    }
    @media screen and (min-width: 991px) {
        .additional-estimate-images-block .gallery-wrap {
            width: 70% !important;
        }
    }

    @media screen and (max-width: 767px) {
        .gallery-wrap {
            width: 100vw;
        }
    }

    .compact-sidebar .gallery-bg,
    .compact-sidebar .gallery-wrap {
        left: 0;
    }

    .gallery-container {
        display: flex;
        justify-content: center;
        align-items: stretch;
        position: relative;
        box-sizing: border-box;
        max-width: 896px;
        height: 120vh;
        background-color: white;
        padding-top: 30px;
    }


    /* isEditing */
    .is-editing.gallery-container {
        /* background-color: #1e1e1e; */
        height: 100%;
    }

    .gallery-container.is-editing .gallery-close,
    .gallery-container.is-editing .gallery-arrow {
        /* color: white; */
    }

    .gallery-align-top .gallery-container:before {
        display: none;
    }

    .gallery-content {
        position: unset;
        display: inline-block;
        width: 100%;
        vertical-align: middle;
        margin: 0 auto;
        text-align: left;
        z-index: 1045;
    }

    .gallery-inline-holder .gallery-content,
    .gallery-ajax-holder .gallery-content {
        width: 100%;
        cursor: auto;
    }

    .gallery-ajax-cur {
        cursor: progress;
    }

    .gallery-zoom-out-cur,
    .gallery-zoom-out-cur .gallery-image-holder .gallery-close {
        cursor: -moz-zoom-out;
        cursor: -webkit-zoom-out;
        cursor: zoom-out;
    }

    .gallery-zoom {
        cursor: pointer;
        cursor: -webkit-zoom-in;
        cursor: -moz-zoom-in;
        cursor: zoom-in;
    }

    .gallery-auto-cursor .gallery-content {
        cursor: auto;
    }

    .gallery-close,
    .gallery-arrow,
    .gallery-preloader,
    .gallery-counter {
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
    }

    .gallery-loading.gallery-figure {
        display: none;
    }

    .gallery-hide {
        display: none !important;
    }

    .gallery-preloader {
        color: #CCCCCC;
        position: absolute;
        top: 50%;
        width: auto;
        text-align: center;
        margin-top: -0.8em;
        left: 8px;
        right: 8px;
        z-index: 1044;
    }

    .gallery-preloader a {
        color: #CCCCCC;
    }

    .gallery-preloader a:hover {
        color: #FFFFFF;
    }

    .gallery-s-ready .gallery-preloader {
        display: none;
    }

    .gallery-s-error .gallery-content {
        display: none;
    }

    button.gallery-close,
    button.gallery-arrow {
        display: grid;
        place-content: center;
        overflow: visible;
        cursor: pointer;
        font-size: 44px;
        background: transparent;
        border: 0;
        -webkit-appearance: none;
        outline: none;
        padding: 0;
        z-index: 1046;
        box-shadow: none;
        touch-action: manipulation;
    }

    button::-moz-focus-inner {
        padding: 0;
        border: 0;
    }

    .gallery-close {
        position: absolute;
        /*top: 60px;*/
        right: 10px;
        text-align: center;
        opacity: 0.65;
        padding: 0 0 0 0;
        font-style: normal;
        font-size: 28px;
        color: #000000;
    }

    .gallery-close:hover,
    .gallery-close:focus {
        opacity: 1;
    }

    .gallery-close:active {
        /*top: 61px;*/
    }

    .gallery-close-btn-in .gallery-close {
        color: #333333;
    }

    .gallery-image-holder .gallery-close,
    .gallery-iframe-holder .gallery-close {
        display: block;
        width: 45px;
        text-align: right;
        color: #1C1F39;
    }

    .gallery-counter {
        color: #CCCCCC;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        color: #1C1F39;
        text-align: right;
        margin-bottom: 20px;
    }

    .gallery-arrow {
        position: absolute;
        opacity: 0.65;
        margin: 0;
        top: 40%;
        background: transparent;
        margin-top: -55px;
        padding: 0;

        -webkit-tap-highlight-color: transparent;
        color: #1C1F39;
        border-radius: 50%;
    }

    .gallery-arrow:active {
        margin-top: -54px;
    }

    .gallery-arrow:hover,
    .gallery-arrow:focus {
        opacity: 1;
    }

    .gallery-arrow:after {
        border-top-width: 13px;
        border-bottom-width: 13px;
        top: 8px;
    }

    .gallery-arrow:before {
        border-top-width: 21px;
        border-bottom-width: 21px;
        opacity: 0.7;
    }

    .gallery-arrow-left {
        left: 20px;
    }

    .gallery-arrow-left:after {
        border-right: 17px solid #FFFFFF;
        margin-left: 31px;
    }

    .gallery-arrow-left:before {
        margin-left: 25px;
        border-right: 27px solid #3F3F3F;
    }

    .gallery-arrow-right {
        right: 10px;
    }

    .gallery-arrow-right:after {
        border-left: 17px solid #FFFFFF;
        margin-left: 39px;
    }

    .gallery-arrow-right:before {
        border-left: 27px solid #3F3F3F;
    }

    .gallery-iframe-holder {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .gallery-iframe-holder .gallery-content {
        line-height: 0;
        width: 100%;
        max-width: 900px;
    }

    .gallery-iframe-holder .gallery-close {
        top: -40px;
    }

    .gallery-iframe-scaler {
        width: 100%;
        height: 0;
        overflow: hidden;
        padding-top: 56.25%;
    }

    .gallery-iframe-scaler iframe {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
        background: #000000;
    }

    /* Main image in popup */
    .gallery-img {
        max-width: 100%;
        height: auto;
        /*object-fit: cover;*/
        object-fit: scale-down;
        border-radius: 8px;
        margin-bottom: 20px;
        position: relative;
    }

    /* The shadow behind the image */
    .gallery-figure {
        display: grid;
        height: 100%;
        position: relative;
        width: 100%;
        line-height: 0;
        padding: 0 57px;
        margin-top: 60px;
    }

    .gallery-figure:after {
        content: "";
        position: absolute;
        left: 0;
        top: 40px;
        bottom: 40px;
        display: block;
        right: 0;
        width: auto;
        height: auto;
        z-index: -1;
    }
    .gallery-figure-new-window{
      display: grid;
      /*height: calc(100vh - 60px);*/
      position: relative;
      width: 100%;
      line-height: 0;
    }

    .gallery-figure-new-window:after {
      content: "";
      position: absolute;
      left: 0;
      top: 40px;
      bottom: 40px;
      display: block;
      right: 0;
      width: auto;
      height: auto;
      z-index: -1;
    }

    .additional-image-height {
        height: 100vh;
    }

    .gallery-figure small {
        color: #BDBDBD;
        display: block;
        font-size: 12px;
        line-height: 14px;
    }

    .gallery-figure figure {
        margin: 0;
    }

    .gallery-title {
        text-align: left;
        line-height: 18px;
        color: #F3F3F3;
        word-wrap: break-word;
        padding-right: 36px;
    }

    .gallery-image-holder .gallery-content {
        max-width: 100%;
    }

    .gallery-gallery .gallery-image-holder .gallery-figure {
        cursor: pointer;
    }

    .gallery-delete {
        position: absolute;
        /*display: block;*/
        right: 10px;
        top: 10px;
        z-index: 1000;
        height: 44px;
        width: 44px;
        border-radius: 3px;
        display: none;
        align-items: center;
        justify-content: center;
        border: 0;
        color: black;
        cursor: pointer;
    }

    .gallery-img:hover .gallery-delete {
        display: flex;
    }

    .gallery-delete svg g {
        fill: black;
    }

    .gellery-delete:hover {
        /* cursor: pointer; */
    }

    .gallery-delete:hover svg g {
        fill: #FF5E5E;
    }

    @media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
        /**
        * Remove all paddings around the image on small screen
        */
        .gallery-img-mobile .gallery-image-holder {
            padding-left: 0;
            padding-right: 0;
        }

        .gallery-img-mobile img.gallery-img {
            padding: 0;
        }

        .gallery-img-mobile .gallery-figure:after {
            top: 0;
            bottom: 0;
        }

        .gallery-img-mobile .gallery-figure small {
            display: inline;
            margin-left: 5px;
        }

        .gallery-img-mobile .gallery-bottom-bar {
            background: rgba(0, 0, 0, 0.6);
            bottom: 0;
            margin: 0;
            top: auto;
            padding: 3px 5px;
            position: fixed;
            box-sizing: border-box;
        }

        .gallery-img-mobile .gallery-bottom-bar:empty {
            padding: 0;
        }

        .gallery-img-mobile .gallery-counter {
            right: 5px;
            top: 3px;
        }


    }

    @media all and (max-width: 900px) {
        .gallery-arrow {
            -webkit-transform: scale(0.75);
            transform: scale(0.75);
        }

        .gallery-arrow-left {
            -webkit-transform-origin: 0;
            transform-origin: 0;
        }

        .gallery-arrow-right {
            -webkit-transform-origin: 100%;
            transform-origin: 100%;
        }

        .gallery-container {
            /* padding-left: 6px;
            padding-right: 6px; */
        }
    }

    /* mobile */
    @media screen and (max-width: 1000px) {
        .gallery-bg,
        .gallery-wrap {
            margin-top: 60px;
            left: 0;
        }
        .gallery-container {
            height: calc(100vh - 160px);
            margin-left: 0;
            margin-top: 50px !important;
        }

        .gallery-close {
            top: 0px;
        }

        .gallery-close:active {
            top: 1px;
        }

        .gallery-delete {
            /* right: 35px; */
        }

        .gallery-figure {
            padding: 0 22px;
        }

        .gallery-arrow {
            background: whitesmoke !important;
        }

        .gallery-arrow-right {
            right: 30px;
        }

        .gallery-arrow-left {
            left: 30px;
        }

        .gallery-img {
            /*max-height: 38vh !important;*/
        }
    }

    @media (max-width: 575px) {
        .gallery-container {
            height: calc(100vh - 350px) !important;
        }

        .gallery-wrap {
            margin-top: 80px;
        }
    }

    @media (max-width: 490px) {
        .gallery-container {
            height: calc(100vh - 500px) !important;
        }
    }

    .actions {
        position: absolute;
        display: grid;
        gap: 10px;
        grid-template-columns: 44px auto;
        align-items: center;
        justify-content: start;
    }

    .actions::after {
        content: "";
        display: table;
        clear: both;
    }

    .actions.actions--topleft {
        top: 0;
        left: 0;
        padding: 10px;
    }

    .actions__item {
        height: 44px;
        width: 44px;
        font-size: 24px;
        border-radius: 3px;
        align-items: center;
        justify-content: center;
        border: 0;
        color: black;
        cursor: pointer;
        position: relative;
    }

    .actions__item:hover,
    .actions__item--active {
        color: #5E79FF;
    }

    .actions__item:active {
        transform: scale(0.95);
    }

    .actions__info {
        display: grid;
        place-items: center;
        grid-column: 1 / 3;
        color: black;
        font-weight: 600; /* semi-bold */
        background-color: #EFEFEF;
        border-radius: 3px;
        padding: 14px 0;
        pointer-events: none;
        transition: all 250ms cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    .actions__zoom-info-circle {
        position: absolute;
        background-color: #5E79FF;
        height: 28px;
        width: 28px;
        border-radius: 50%;
        color: white;
        font-size: 9px;
        display: grid;
        place-items: center;
        left: -14px;
        top: -14px;
        box-shadow: 2px 2px 3px rgba(56, 38, 161, 0.2);
    }

    .slide-fade-enter,
    .slide-fade-leave-to {
        transform: translateY(-5px) scale(1.1);
        opacity: 0;
    }

    .download_edit_wrap {
        margin-left: 55px;
        text-align: left !important;
        position: absolute;
    }

    .download_edit_wrap-bottom {
        margin-top: 30px !important;
        text-align: right !important;
    }

    .delete-image-tooltip {
        z-index: 9999;
        top: -200px !important;
    }
    @media (max-width: 700px) {
      .gallery-arrow-mobile {
        display: block;
      }
      .gallery-arrow{
        top: 50% !important;
        margin-top: 0;
      }
      .gallery-arrow:active{
        margin-top: 0;
      }
      .gallery-arrow-min-width{
        display: none;
      }
      .gallery-arrow-right {
        right: 10px;
      }

      .gallery-arrow-left {
        left: 10px;
      }
      .gallery-container{
        height: 100vh !important;
      }
    }
    @media (max-width: 1024px) {
      .actions.actions--topleft {
        display: none;
      }
    }
    @media (max-width: 1480px) {
      .download_edit_wrap-editor{
        right: 250px !important;
      }
      .gallery-figure-new-window >>> .btn-image-editor-save{
        right: 350px;
      }
    }
    @media (max-width: 1150px) {
      .download_edit_wrap-editor{
        right: 80px !important;
        top: 90px !important;
      }
      .gallery-figure-new-window >>> .btn-image-editor-save{
        right: 180px;
        top: 90px;
      }
    }
    @media screen and (max-width: 768px) and (orientation: landscape){
      .gallery-wrap {
        left: 0 !important;
      }
    }
    @media screen and (max-width: 1000px) and (orientation: landscape) {
      .gallery-arrow-mobile {
        display: block;
      }
      .gallery-close-desktop{
        display: none;
      }
      .gallery-close.gallery-close-mobile{
        display: block !important;
        top: 10px;
        background-color: #efefef;
        right: -40px;
        border-radius: 6px;
        opacity: 1;
      }
      .gallery-delete{
        right: 45px;
      }
      .gallery-arrow{
        top: 50% !important;
        margin-top: 0;
      }
      .gallery-bg{
        background-color: white;
      }
      .gallery-arrow:active{
        margin-top: 0;
      }
      .gallery-arrow-min-width{
        display: none;
      }
      .gallery-arrow-right {
        right: 10px;
      }
      .gallery-arrow-left {
        left: 10px;
      }
      .gallery-img >>> .zoom-on-hover img{
        object-fit: contain;
        height: 100vh;
      }
      .gallery-container{
        height: 100vh !important;
        margin-top: -65px !important;
        padding-top: 0;
      }
      .gallery-wrap {
        left: 220px;
      }
      .V3 .compact-sidebar .gallery-wrap{
        left: 70px;
      }
    }
    @media screen and (max-width: 767px) and (orientation: landscape) {
      .gallery-close.gallery-close-mobile{
        right: -10px;
      }
    }
    @media screen and (min-width: 767px) and (max-width: 1300px) and (orientation: portrait) {
      .V3 .compact-sidebar .gallery-wrap{
        left: 70px;
      }
      .gallery-wrap{
        left: 220px;
      }
    }
    @media screen and (max-width: 1300px) and (orientation: portrait) {
      .mobile-navigation-portrait{
        top: -62px;
        right: 0;
      }
      .mobile-navigation-portrait .mobile-navigation-btn{
        width: 40px;
        height: 40px;
        margin: 0 8px;

      }
      .mobile-navigation-portrait .mobile-navigation-btn:first-child{
        bottom: 3px !important;
      }
    }
</style>
